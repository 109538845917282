<template>
    <v-navigation-drawer 
        app
        :mini-variant.sync="mini"
        v-model="openMenu"
    >
        <v-list>
            <v-list-item :style="mini ? 'height: 51px !important;': 'margin-left: 30%;'">
                <a href="/" v-if="mini == false">
                    <v-img :src="require('@/assets/images/logo.svg')" max-height="90" max-width="60" />
                </a>
                <v-app-bar-nav-icon
                    v-else
                    @click.stop="openCloseSideMenu" style="border: none; margin-left: -5px;">
                    <v-icon 
                        color="var(--color__cinza_escuro)"
                    >
                        mdi-playlist-plus
                    </v-icon>
                </v-app-bar-nav-icon>
            </v-list-item>
        </v-list>

        <v-divider></v-divider>

        <!-- FIXEDs MENU -->
        <v-list dense>
            
            <a href="/" style="text-decoration: none !important;">
                <v-list-item 
                    link
                >
                    <v-tooltip right>
                        <template v-slot:activator="{ on }">
                            <v-list-item-icon v-on="on">
                                <v-icon color="var(--color__main)">mdi mdi-home</v-icon>
                            </v-list-item-icon>

                            <v-list-item-title>Home</v-list-item-title>

                        </template>
                        <span>Go to Home</span>
                    </v-tooltip>
                </v-list-item>
            </a>
                        
            <v-list-item 
                link 
                :to="{ name: 'completedJobs' }"
            >
                <v-tooltip right>
                    <template v-slot:activator="{ on }">
                        <v-list-item-icon v-on="on">
                            <v-icon color="var(--color__main)">mdi mdi-coffee-maker-check</v-icon>
                        </v-list-item-icon>

                        <v-list-item-title>Completed Jobs</v-list-item-title>

                    </template>
                    <span>Completed Jobs</span>
                </v-tooltip>
            </v-list-item>


            <v-list-item v-if="mini == false">

                <v-list-item-title class="titleMenu">Notifications</v-list-item-title>

            </v-list-item>

                        
            <v-list-item 
                link 
                :to="{ name: 'toDoUrgent', params: { categoryName: ' - Urgent' } }" 
                :key="-5" 
                @click="setIDToDoCategory(1, 0, 0, 0)"
            >

                <v-tooltip right>
                    <template v-slot:activator="{ on }">
                        <v-list-item-icon v-on="on">
                            <v-badge
                                :content="qtUrgent"
                                :value="qtUrgent"
                                left
                                overlap
                                color="var(--color__red)"
                            >
                                <v-icon color="var(--color__main)">mdi mdi-fire</v-icon>
                            </v-badge>
                        </v-list-item-icon>

                        <v-list-item-title>Urgent</v-list-item-title>

                    </template>
                    <span>Urgent</span>
                </v-tooltip>
            </v-list-item>

                        
            <v-list-item 
                link 
                :to="{ name: 'toDoSystem', params: { categoryName: ' - System' } }" 
                :key="-4"
                @click="setIDToDoCategory(0, 1, 0, 0)"
            >

                <v-tooltip right>
                    <template v-slot:activator="{ on }">
                        <v-list-item-icon v-on="on">
                            <v-badge
                                :content="qtSystem"
                                :value="qtSystem"
                                left
                                overlap
                                color="var(--color__red)"
                            >
                                <v-icon color="var(--color__main)">mdi mdi-bell</v-icon>
                            </v-badge>
                        </v-list-item-icon>

                        <v-list-item-title>System</v-list-item-title>
                    </template>
                    <span>System</span>
                </v-tooltip>

            </v-list-item>
                        
                        
            <v-list-item 
                link 
                :to="{ name: 'toDoAssignedToMe', params: { categoryName: ' - Assigned To Me' } }" 
                :key="-3"
                @click="setIDToDoCategory(0, 0, 1, 0)"
            >

                <v-tooltip right>
                    <template v-slot:activator="{ on }">
                        <v-list-item-icon v-on="on">
                            <v-badge
                                :content="qtAssignedToMe"
                                :value="qtAssignedToMe"
                                left
                                overlap
                                color="var(--color__red)"
                            >
                                <v-icon color="var(--color__main)">mdi mdi-clipboard-check</v-icon>
                            </v-badge>
                        </v-list-item-icon>

                        <v-list-item-title>Assigned To Me</v-list-item-title>
                    </template>
                    <span>Assigned To Me</span>
                </v-tooltip>

            </v-list-item>
                        
                        
            <v-list-item 
                link 
                :to="{ name: 'inspection' }" 
                :key="-2"                
            >

                <v-tooltip right>
                    <template v-slot:activator="{ on }">
                        <v-list-item-icon v-on="on">
                            <v-badge
                                :content="qtdInspection"
                                :value="qtdInspection"
                                left
                                overlap
                                color="var(--color__red)"
                            >
                                <v-icon color="var(--color__main)">mdi mdi-magnify-expand</v-icon>
                            </v-badge>
                        </v-list-item-icon>

                        <v-list-item-title>Inspections</v-list-item-title>
                    </template>
                    <span>Inspections</span>
                </v-tooltip>

            </v-list-item>
                        
                        
            <v-list-item 
                link 
                :to="{ name: 'workOrder' }" 
                :key="-1"                
            >

                <v-tooltip right>
                    <template v-slot:activator="{ on }">
                        <v-list-item-icon v-on="on">
                            <v-badge
                                :content="qtdWorkOrder"
                                :value="qtdWorkOrder"
                                left
                                overlap
                                color="var(--color__red)"
                            >
                                <v-icon color="var(--color__main)">mdi mdi-briefcase-outline</v-icon>
                            </v-badge>
                        </v-list-item-icon>

                        <v-list-item-title>Work Order</v-list-item-title>
                    </template>
                    <span>Work Order</span>
                </v-tooltip>

            </v-list-item>
            

            <v-list-item 
                v-for="(itemToDoCateogory, index) in userLoggedGetters.listToDoCategory" 
                :key="index" 
                link 
                :to="{ name: itemToDoCateogory.routeName, params: { categoryName: ' - ' + itemToDoCateogory.description } }"
                @click="setIDToDoCategory(0, 0, 0, itemToDoCateogory.id)"
            >                                 
                <v-tooltip right>
                    <template v-slot:activator="{ on }">
                        <v-list-item-icon v-on="on">
                            <v-badge
                                :content="listToDoByCategory.filter(cat => cat.idTodoCategory === itemToDoCateogory.id).length"
                                :value="listToDoByCategory.filter(cat => cat.idTodoCategory === itemToDoCateogory.id).length"
                                left
                                overlap
                                color="var(--color__red)"
                            >
                                <v-icon color="var(--color__main)">{{ itemToDoCateogory.mdiIcon }}</v-icon>
                            </v-badge>
                        </v-list-item-icon>

                        <v-list-item-title>{{ itemToDoCateogory.description }}</v-list-item-title>
                    </template>
                    <span>{{ itemToDoCateogory.description }}</span>
                </v-tooltip>

            </v-list-item>

        </v-list>

        <v-divider></v-divider>

        <!-- MENU -->
        <v-list dense v-if="itemGrupoMenu !== null">

            <v-list-item v-if="mini == false">

                <v-list-item-title class="titleMenu">Menu</v-list-item-title>

            </v-list-item>
                        
            <v-list-item 
                v-for="(itemMenu, index) in itemGrupoMenu.listMenu.filter(mnu => mnu.showInMenu === true)" 
                :key="index" 
                link 
                :to="`${itemMenu.path}`"
                @click="setIDToDoCategory(0, 0, 0, 0)"
            >
                <v-tooltip right>
                    <template v-slot:activator="{ on }">

                        <v-list-item-icon v-on="on">
                            <v-icon color="var(--color__main)">{{ itemMenu.mdiIcon }}</v-icon>
                        </v-list-item-icon>

                        <v-list-item-title>{{ itemMenu.description }}</v-list-item-title>

                    </template>
                    <span>{{ itemMenu.description }}</span>
                </v-tooltip>

            </v-list-item>

        </v-list>

    </v-navigation-drawer>

</template>

<script>
    
    import { mapGetters } from 'vuex';
    import Helpers from '@/utilities/Helpers';

    export default {

        mixins: [Helpers],

        data: () => ({

            itemGrupoMenu: null,
            listToDoByCategory: [],
            qtUrgent: 0,
            qtSystem: 0,
            qtAssignedToMe: 0,
            qtdWorkOrder: 0,
            qtdInspection: 0,
            openMenu: null,
        }),

        computed: {
            ...mapGetters('logInModule', { userLoggedGetters: 'searchState', menuGetter: 'menuState' }),

            mini: {
                get: function() {
                    return this.$store.state.menuSide
                },
                
                set: function () { }
            }
        },

        watch: {
            "menuGetter.reload"() {
                this.itemGrupoMenu = this.menuGetter.itemGrupoMenu;

                let itemGrupoMenuFiltered = this.itemGrupoMenu.listMenu.filter(mnu => mnu.showInMenu === true);

                if (itemGrupoMenuFiltered.length != 0) {
                    
                    this.$router.push({ path: itemGrupoMenuFiltered[0].path });
                }
                else {
                    itemGrupoMenuFiltered = this.itemGrupoMenu.listMenu.filter(mnu => mnu.id == this.userLoggedGetters.profile.idMenuDefault)[0];

                    if (itemGrupoMenuFiltered != null || itemGrupoMenuFiltered != undefined) {
                        this.$router.push({ path: itemGrupoMenuFiltered.path });
                    }
                }
            },

            "$vuetify.breakpoint.mobile"() {

                this.checkMobile();
            },

            "$store.state.menuSide"() {

                this.checkMobile();
            }
        },

        methods: {

            checkMobile() {
                if (this.$vuetify.breakpoint.mobile == true) {
                    this.openMenu = true;
                }
                else {
                    this.openMenu = null;
                }
            },

            openCloseSideMenu() {
                this.checkMobile();
                var menuSide = !this.$store.state.menuSide;
                this.openSideMenu(menuSide)
            },
            
            setIDToDoCategory(urgent, system, assignedToMe, idTodoCategory) {
                let jsonToDoCategory = {
                    urgent,
                    system,
                    assignedToMe,
                    idTodoCategory,
                    done: null
                }

                localStorage.setItem('jsonToDoCategory', JSON.stringify(jsonToDoCategory));
            }
        },

        async created() {

            let showedModalToDo = false;

            const intervalNotifications = setInterval( async () => { 
                
                let token = localStorage.getItem('token');

                if (token == null || token == undefined) {
                    clearInterval(intervalNotifications)
                }

                if (token != null && token != undefined && token != '') {
                
                    //URGENT
                    let toDoCategoryRequest = { urgent: 1, system: 0, assignedToMe: 0, idTodoCategory: 0, done: 0 }
                    let listToDo = await this.$store.dispatch("toDoModule/ListByCategory", toDoCategoryRequest);

                    if (listToDo != null && listToDo != undefined) {
                        this.qtUrgent = listToDo.length;
                    }

                    //SYSTEM
                    toDoCategoryRequest = { urgent: 0, system: 1, assignedToMe: 0, idTodoCategory: 0, done: 0 }
                    listToDo = await this.$store.dispatch("toDoModule/ListByCategory", toDoCategoryRequest);
                    
                    if (listToDo != null && listToDo != undefined) {
                        this.qtSystem = listToDo.length;
                    }

                    //ASSIGNED TO ME
                    toDoCategoryRequest = { urgent: 0, system: 0, assignedToMe: 1, idTodoCategory: 0, done: 0 }
                    listToDo = await this.$store.dispatch("toDoModule/ListByCategory", toDoCategoryRequest);

                    if (listToDo != null && listToDo != undefined) {
                        this.qtAssignedToMe = listToDo.length;
                    }

                    //ALL OTHERS CATEGORIES
                    toDoCategoryRequest = { urgent: 0, system: 0, assignedToMe: 0, idTodoCategory: 0, done: 0 }
                    this.listToDoByCategory = await this.$store.dispatch("toDoModule/ListByCategory", toDoCategoryRequest);

                    if (this.qtUrgent > 0 && showedModalToDo == false) {
                        showedModalToDo = true;
                        this.showModalToDo(this.qtUrgent);
                    }

                    //WORK ORDER
                    this.qtdWorkOrder = await this.$store.dispatch("workOrderModule/ListNotification");

                    //INSPECTION
                    this.qtdInspection = await this.$store.dispatch("inspectionModule/ListNotification");
                }

            }, 5000);


            const intervalDocusign = setInterval( async () => { 
                
                let token = localStorage.getItem('token');

                if (token == null || token == undefined) {
                    clearInterval(intervalDocusign)
                }

                if (token != null && token != undefined && token != '') {

                    //CHECK ALL PENDING DOCUMENTS
                    this.$store.dispatch("docusignModule/CheckAllPendingDocuments");
                }

            }, 60000);
        }
    };

</script>

<style>
    .v-badge__badge {
        border: solid 2px;
    }
</style>