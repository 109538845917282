import { 
    showDialog, 
    showToast, 
    showLoading, 
    hideLoading, 
    showModalToDo, 
    hideModalToDo, 
    openSideMenu, 
    downloadFile,
    exportFile,
    syncScroll,
    resizePreviewImage,
    getStatusColor,
    docusignDownloadDocument,
    formatDate,
    copyToClipboard
} from './Utils'

export default {

    methods: {

        showLoading: function() {
            showLoading();
        },

        hideLoading: function () {
            hideLoading();
        },

        showModalToDo: function (qtdeToDo) {
            showModalToDo(qtdeToDo);
        },

        hideModalToDo: function (qtdeToDo) {
            hideModalToDo(qtdeToDo);
        },

        openSideMenu: function (open) {
            openSideMenu(open);
        },

        showDialog: function (text, func, params) {
            showDialog(text, func, params);
        },

        showToast: function (state, title, content) {
            showToast(state, title, content);
        },

        downloadFile: function (url, id, contentType, fileName) {
            downloadFile(url, id, contentType, fileName);
        },

        exportFile: function (url, request, fileName) {
            exportFile(url, request, fileName);
        },

        syncScroll: function () {
            syncScroll();
        },

        resizePreviewImage: function (filesPhotos, listPhotos) {
            resizePreviewImage(filesPhotos, listPhotos);
        },

        getStatusColor: function (status) {
            getStatusColor(status);
        },

        docusignDownloadDocument: function (idEnvelop, fileName) {
            docusignDownloadDocument(idEnvelop, fileName);
        },

        formatDate: function (date) {
            return formatDate(date);
        },

        copyToClipboard: function (text) {
            return copyToClipboard(text);
        },
    }
}