
import Vue from 'vue'

const getDefaultState = () => {
    return {
        colorSelectionProduct: {
            id: 0,
            description: "",
            needsApproval: "",
        }
    }
};

const state = getDefaultState();

const actions = {

    resetState({ commit }) {
        commit('resetState');
    },

    saveState({ commit }, payload) {
        commit('saveState', payload);
    },

    async List() {

        try {
            const result = await Vue.prototype.$vanKirkApi.get("common/color-selection-product/list",
            {
                headers: {
                    "content-type": "application/json"
                }
            });

            if (result.data) {
                const listProduct = result.data.result;

                return listProduct;
            }
            else {
                return [];
            }
        }
        catch(ex) {
            return {
                success: false,
                message: `Error to list color selection products: [${ex}]`
            };
        }
    },

    async GetById(_, id) {

        try {
            const result = await Vue.prototype.$vanKirkApi.get(`common/color-selection-product/${id}`,
            {
                headers: {
                    "content-type": "application/json"
                }
            });

            if (result.status === 200) {
                return result.data;
            }
            else {
                return {
                    success: false,
                    result: `Error to get color selection products`
                };
            }
        }
        catch(ex) {
            return {
                success: false,
                result: `Error to get color selection products: [${ex}]`
            };
        }
    },

    async CreateUpdate(_, productRequest) {

        try {
            const result = await Vue.prototype.$vanKirkApi.post(`common/color-selection-product/create-update`, productRequest,
            {
                headers: {
                    "content-type": "application/json"
                }
            });

            if (result.status === 204) {

                let msg = "Register updated with success!";

                if (productRequest.id === 0) {
                    msg = "Record created successfully";
                }

                return {
                    success: true,
                    message: msg
                };
            }
            else {
                return {
                    success: false,
                    message: "Error to create/update register!"
                };
            }
        }
        catch(error) {

            if (error.response && error.response.status === 400) {
                return {
                    success: false,
                    message: `Error to create/update register: [${error.response.data}]`
                };
            }
            else {

                return {
                    success: false,
                    message: `Error to create/update register: [${error}]`
                };
            }
        }
    },

    async Delete(_, id) {

        try {
            const result = await Vue.prototype.$vanKirkApi.delete(`common/color-selection-product/${id}`,
            {
                headers: {
                    "content-type": "application/json"
                }
            });

            if (result.status === 200) {

                return {
                    success: true,
                    message: "Register deleted with success!"
                };
            }
        }
        catch(error) {

            if (error.response && error.response.status === 400) {
                return {
                    success: false,
                    message: `Error to delete the register: [${error.response.data}]`
                };
            }
            else {

                return {
                    success: false,
                    message: `Error to delete the register: [${error}]`
                };
            }
        }
    },
}

const mutations = {

    saveState: (state, newValue) => {
        Object.assign(state.colorSelectionProduct, newValue);
    },

    resetState: (state) => {
        Object.assign(state, getDefaultState());
    }
}

const getters = {

    searchState: (state) => {
        return state.colorSelectionProduct;
    },
}

export default {
    namespaced: true,
    state,
    mutations,
    getters,
    actions
}