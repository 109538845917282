
import Vue from 'vue'
import { userFormatServer } from "./userFormatServer";

const getDefaultState = () => {
    return {
        user: {
          id: 0,
          nome: "",
          email: "",
        },
    }
};

const state = getDefaultState();

const actions = {

    resetState({ commit }) {
        commit('resetState');
    },

    saveState({ commit }, payload) {
        commit('saveState', payload);
    },

    async GetById( { commit }, idUser) {

        try {
            const result = await Vue.prototype.$vanKirkApi.get(`user/${idUser}`,
            {
                headers: {
                    "content-type": "application/json"
                }
            });

            if (result.status === 200) {

                let userResponse = result.data;

                commit('saveState', userResponse);

                return userResponse;
            }
            else {
                return false
            }
        }
        catch(ex) {
            console.log("Error to get user", ex);
            return false;
        }
    },

    async UpdateProfile( _, requestProfile) {

        try {
            const result = await Vue.prototype.$vanKirkApi.put(`user/update-profile/${requestProfile.idUser}`, requestProfile,
            {
                headers: {
                "content-type": "application/json"
                }
            });

            if (result.status === 200) {
                return {
                    success: true,
                    message: `The information has been updated successfully!`
                };
            }
            else {
                return {
                    success: false,
                    message: `Error to update profile informations. Please check the data provided!`
                };
            }
        }
        catch(error) {

            if (error.response && error.response.status === 400) {
                return {
                    success: false,
                    message: `Error updating profile informations: [${error.response.data}]`
                };
            }
            else {

                return {
                    success: false,
                    message: `Error updating profile informations: [${error}]`
                };
            }
        }
    },

    async List() {

        try {
            const result = await Vue.prototype.$vanKirkApi.get("user/list",
            {
                headers: {
                    "content-type": "application/json"
                }
            });

            if (result.data) {
                let listUser = result.data.result;
                return listUser;
            }
            else {
                return [];
            }
        }
        catch(ex) {
            return {
                success: false,
                message: `Error to list users: [${ex}]`
            };
        }
    },

    async CreateUpdate(_, userRequest) {

        try {
            let userRequestFormated = userFormatServer(JSON.parse(JSON.stringify(userRequest)));

            const result = await Vue.prototype.$vanKirkApi.post(`user/create-update`, userRequestFormated,
            {
                headers: {
                    "content-type": "application/json"
                }
            });

            if (result.status === 204) {

                let msg = "Register updated with success!";

                if (userRequest.id === 0) {
                    msg = "Record created successfully";
                }

                return {
                    success: true,
                    message: msg
                };
            }
            else {
                return {
                    success: false,
                    message: "Error to create/update register!"
                };
            }
        }
        catch(error) {

            if (error.response && error.response.status === 400) {
                return {
                    success: false,
                    message: `Error to create/update register: [${error.response.data}]`
                };
            }
            else {

                return {
                    success: false,
                    message: `Error to create/update register: [${error}]`
                };
            }
        }
    },

    async UpdateStatus(_, { id, statusId }) {

        try {
            const result = await Vue.prototype.$vanKirkApi.put(`user/update-status/${id}/${statusId}`,
            {
                headers: {
                    "content-type": "application/json"
                }
            });

            if (result.status === 204) {
                return {
                    success: true,
                    message: "Status updated with success!"
                };
            }
            else {
                return {
                    success: false,
                    message: "Error updating the status!"
                };
            }
        }
        catch(ex) {
            return {
                success: false,
                message: `Error updating the status: [${ex}]`
            };
        }
    },

    async ChangePassword( _, passwordRequest) {

        try {
            const result = await Vue.prototype.$vanKirkApi.put(`user/change-password/${passwordRequest.idUser}`, passwordRequest,
            {
                headers: {
                "content-type": "application/json"
                }
            });

            if (result.status === 200) {
                return {
                    success: true,
                    message: `The password has been changed successfully!`
                };
            }
            else {
                return {
                    success: false,
                    message: `Error to change password. Please try again!`
                };
            }
        }
        catch(error) {

            if (error.response && error.response.status === 400) {
                return {
                    success: false,
                    message: `Error to change password: [${error.response.data}]`
                };
            }
            else {

                return {
                    success: false,
                    message: `Error to change password: [${error}]`
                };
            }
        }
    },
    
    async ResetPassword( _, newPasswordRequest) {

        try {
            const result = await Vue.prototype.$vanKirkApi.post(`user/reset-password`, newPasswordRequest,
            {
                headers: {
                "content-type": "application/json"
                }
            });

            console.log('result', result);

            if (result.status === 200) {
                return {
                    success: true,
                    message: `The password has been changed successfully!`
                };
            }
            else {
                return {
                    success: false,
                    message: `Error to change password. Please try again!`
                };
            }
        }
        catch(error) {

            if (error.response && error.response.status === 400) {
                return {
                    success: false,
                    message: `Error to change password: [${error.response.data}]`
                };
            }
            else {

                return {
                    success: false,
                    message: `Error to change password: [${error}]`
                };
            }
        }
    },

    async ResetUserPassword( _, resetUserPasswordRequest) {

        try {
            const result = await Vue.prototype.$vanKirkApi.put(`user/reset-user-password/${resetUserPasswordRequest.idUser}`, resetUserPasswordRequest,
            {
                headers: {
                "content-type": "application/json"
                }
            });

            if (result.status === 200) {
                return {
                    success: true,
                    message: `The password has been reseted successfully!`
                };
            }
            else {
                return {
                    success: false,
                    message: `Error to reset user password. Please try again!`
                };
            }
        }
        catch(error) {

            if (error.response && error.response.status === 400) {
                return {
                    success: false,
                    message: `Error to reset user password: [${error.response.data}]`
                };
            }
            else {

                return {
                    success: false,
                    message: `Error to reset user password: [${error}]`
                };
            }
        }
    },

    async ListSalesPerson() {

        try {
            const result = await Vue.prototype.$vanKirkApi.get("user/list-sales-person",
            {
                headers: {
                    "content-type": "application/json"
                }
            });

            if (result.data) {
                const listUser = result.data.result;
                return listUser;
            }
            else {
                return [];
            }
        }
        catch(ex) {
            return {
                success: false,
                message: `Error to list sales person users: [${ex}]`
            };
        }
    },
}

const mutations = {

    saveState: (state, newValue) => {
        Object.assign(state.user, newValue);
    },

    resetState: (state) => {
        Object.assign(state, getDefaultState());
    }
}

const getters = {

    searchState: (state) => {
        return state.user;
    },
}

export default {
    namespaced: true,
    state,
    mutations,
    getters,
    actions
}
