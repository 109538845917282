
import Vue from 'vue'

const actions = {

    async CheckAllPendingDocuments() {

        try {
            Vue.prototype.$vanKirkApi.get(`docusign/check-all-documents`,
            {
                headers: {
                    "content-type": "application/json"
                }
            });
        }
        catch(ex) {
            return {
                success: false,
                result: `Error to check all status: [${ex}]`
            };
        }
    },

    async CheckStatus(_, idEnvelop) {

        try {
            const result = await Vue.prototype.$vanKirkApi.get(`docusign/check-status/${idEnvelop}`,
            {
                headers: {
                    "content-type": "application/json"
                }
            });

            if (result.status === 204) {
                return {
                    success: true,
                    message: "Status checked with successfully!"
                };
            }
            else {
                return {
                    success: false,
                    result: `Error to check status`
                };
            }
        }
        catch(ex) {
            return {
                success: false,
                result: `Error to check status: [${ex}]`
            };
        }
    },

    async GetByTypeDocument(_, { typeDocument, id }) {

        try {
            const result = await Vue.prototype.$vanKirkApi.get(`docusign/${typeDocument}/${id}`,
            {
                headers: {
                    "content-type": "application/json"
                }
            });

            if (result.status === 200) {
                return result.data;
            }
            else {
                return {
                    success: false,
                    result: `Error to get docusign`
                };
            }
        }
        catch(ex) {
            return {
                success: false,
                result: `Error to get docusign: [${ex}]`
            };
        }
    },

    async SendDocument(_, docusignRequest) {

        try {
            const result = await Vue.prototype.$vanKirkApi.post(`docusign/send-document`, docusignRequest,
            {
                headers: {
                    "content-type": "application/json"
                }
            });

            if (result.status === 204) {

                return {
                    success: true,
                    message: "Document sent with success!"
                };
            }
            else {
                return {
                    success: false,
                    message: "Error to send document!"
                };
            }
        }
        catch(error) {

            if (error.response && error.response.status === 400) {
                return {
                    success: false,
                    message: `Error to send document: [${error.response.data}]`
                };
            }
            else {

                return {
                    success: false,
                    message: `Error to send document: [${error}]`
                };
            }
        }
    },

    async CancelDocument(_, idEnvelop) {

        try {
            const result = await Vue.prototype.$vanKirkApi.put(`docusign/cancel-document/${idEnvelop}`,
            {
                headers: {
                    "content-type": "application/json"
                }
            });

            if (result.status === 204) {

                return {
                    success: true,
                    message: "Document canceled with success!"
                };
            }
            else {
                return {
                    success: false,
                    message: "Error to cancel document!"
                };
            }
        }
        catch(error) {

            if (error.response && error.response.status === 400) {
                return {
                    success: false,
                    message: `Error to cancel document: [${error.response.data}]`
                };
            }
            else {

                return {
                    success: false,
                    message: `Error to cancel document: [${error}]`
                };
            }
        }
    },
}


export default {
    namespaced: true,
    actions
}