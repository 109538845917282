import Vue from 'vue'

const getDefaultState = () => {
    return {
        PaymentsConfiguration: {
            id: 0,
            description: ""
        }
    }
};

const state = getDefaultState();

const actions = {

    resetState({ commit }) {
        commit('resetState');
    },

    saveState({ commit }, payload) {
        commit('saveState', payload);
    },
    
    async GetPaymentsById(_, idServicePayments) {

        try {
            const result = await Vue.prototype.$vanKirkApi.get(`service/get-payments/${idServicePayments}`,
            {
                headers: {
                    "content-type": "application/json"
                }
            });

            if (result.status === 200) {
                return result.data;
            }
            else {
                return {
                    success: false,
                    result: `Error to get payment`
                };
            }
        }
        catch(ex) {
            return {
                success: false,
                result: `Error to get payment: [${ex}]`
            };
        }
    },

    async ListTypePayments() {

        try {
            const result = await Vue.prototype.$vanKirkApi.get(`service/list-type-payments`,
            {
                headers: {
                    "content-type": "application/json"
                }
            });

            if (result.data) {
                const listTypePayments = result.data.result;

                return listTypePayments;
            }
            else {
                return [];
            }
        }
        catch(ex) {
            return {
                success: false,
                message: `Error to list type payments: [${ex}]`
            };
        }
    },

    async ListPayments(_, { idService = 0, listPendingPayments = false }) {

        try {
            const result = await Vue.prototype.$vanKirkApi.get(`service/list-payments/${idService}/${listPendingPayments}`,
            {
                headers: {
                    "content-type": "application/json"
                }
            });

            if (result.data) {
                const listPayments = result.data.result;

                return listPayments;
            }
            else {
                return [];
            }
        }
        catch(ex) {
            return {
                success: false,
                message: `Error to list payments: [${ex}]`
            };
        }
    },

    async CreateUpdatePayments(_, servicePaymentsRequest) {

        try {

            const result = await Vue.prototype.$vanKirkApi.post(`service/create-update-payments`, servicePaymentsRequest,
            {
                headers: {
                    "content-type": "application/json"
                }
            });

            if (result.status === 204) {

                const msg = "Payment updated with successfully";

                return {
                    success: true,
                    message: msg
                };
            }
            else {
                return {
                    success: false,
                    message: "Error to create/update payment!"
                };
            }
        }
        catch(error) {

            if (error.response && error.response.status === 400) {
                return {
                    success: false,
                    message: `Error to create/update payment: [${error.response.data}]`
                };
            }
            else {

                return {
                    success: false,
                    message: `Error to create/update payment: [${error}]`
                };
            }
        }
    },
}

const mutations = {

    saveState: (state, newValue) => {
        Object.assign(state.PaymentsConfiguration, newValue);
    },

    resetState: (state) => {
        Object.assign(state, getDefaultState());
    }
}

const getters = {

    searchState: (state) => {
        return state.PaymentsConfiguration;
    },
}

export default {
    namespaced: true,
    state,
    mutations,
    getters,
    actions
}
