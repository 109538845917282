
import Vue from 'vue'

const actions = {

    async SendDocument(_, docusignRequest) {

        try {
            const result = await Vue.prototype.$vanKirkApi.post(`docusign/send-document`, docusignRequest,
            {
                headers: {
                    "content-type": "application/json"
                }
            });

            if (result.status === 200) {

                return {
                    success: true,
                    message: "Document sent with success!"
                };
            }
            else {
                return {
                    success: false,
                    message: "Error to send document!"
                };
            }
        }
        catch(error) {

            if (error.response && error.response.status === 400) {
                return {
                    success: false,
                    message: `Error to send document: [${error.response.data}]`
                };
            }
            else {

                return {
                    success: false,
                    message: `Error to send document: [${error}]`
                };
            }
        }
    },
}


export default {
    namespaced: true,
    actions
}