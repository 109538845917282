import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VDivider } from 'vuetify/lib/components/VDivider';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"text-center"},[_c(VDialog,{attrs:{"transition":"dialog-top-transition","persistent":"","width":"500","fullscreen":_vm.$vuetify.breakpoint.mobile},model:{value:(_vm.$store.state.modalToDo),callback:function ($$v) {_vm.$set(_vm.$store.state, "modalToDo", $$v)},expression:"$store.state.modalToDo"}},[_c(VCard,[_c(VCardTitle,{staticClass:"text-h5 lighten-2",staticStyle:{"border-bottom":"solid 1px var(--color__cinza)","background-color":"var(--color__red)","color":"#ffffff"}},[_vm._v(" Urgent Items ")]),_c(VCardText,{staticClass:"justify-center",staticStyle:{"margin-top":"70px","text-align":"center","height":"100px !important"}},[_c('label',{staticStyle:{"font-size":"20px"}},[_vm._v("You have "),_c('label',{staticStyle:{"font-size":"30px","color":"var(--color__red)"}},[_vm._v(_vm._s(_vm.$store.state.qtdeToDo))]),_vm._v(" pending items in your to-do list")])]),_c(VDivider),_c(VCardActions,[_c('div',{staticStyle:{"width":"100%","text-align":"center"}},[_c(VBtn,{staticClass:"mx-2",attrs:{"outlined":"","to":{ name: 'toDoUrgent', params: { categoryName: ' - Urgent' } }},on:{"click":function($event){return _vm.setIDToDoCategory(1, 0, 0, 0)}}},[_vm._v(" View Pending Items ")])],1)])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }