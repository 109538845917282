
import Vue from 'vue'

const actions = {

    async SalesByMonth(_, filterRequest) {

        try {
            const result = await Vue.prototype.$vanKirkApi.post("report/sales-by-month", filterRequest,
            {
                headers: {
                    "content-type": "application/json"
                }
            });

            if (result.data) {
                const listReport = result.data.result;

                return listReport;
            }
            else {
                return [];
            }
        }
        catch(ex) {
            return {
                success: false,
                message: `Error to list report by month: [${ex}]`
            };
        }
    },

    async SalesByStatus(_, filterRequest) {

        try {
            const result = await Vue.prototype.$vanKirkApi.post("report/sales-by-status", filterRequest,
            {
                headers: {
                    "content-type": "application/json"
                }
            });

            if (result.data) {
                const listReport = result.data.result;

                return listReport;
            }
            else {
                return [];
            }
        }
        catch(ex) {
            return {
                success: false,
                message: `Error to list report by status: [${ex}]`
            };
        }
    },

    async SalesBySalesPerson(_, filterRequest) {

        try {
            const result = await Vue.prototype.$vanKirkApi.post("report/sales-by-salesperson", filterRequest, 
            {
                headers: {
                    "content-type": "application/json"
                }
            });

            if (result.data) {
                const listReport = result.data.result;

                return listReport;
            }
            else {
                return [];
            }
        }
        catch(ex) {
            return {
                success: false,
                message: `Error to list report by sales person: [${ex}]`
            };
        }
    },

    async SalesBySalesPersonAnalytical(_, filterRequest) {

        try {
            const result = await Vue.prototype.$vanKirkApi.post("report/sales-by-salesperson-analytical", filterRequest, 
            {
                headers: {
                    "content-type": "application/json"
                }
            });

            if (result.data) {
                const listReport = result.data.result;

                return listReport;
            }
            else {
                return [];
            }
        }
        catch(ex) {
            return {
                success: false,
                message: `Error to list report by sales person analytical: [${ex}]`
            };
        }
    },
}

export default {
    namespaced: true,
    actions
}