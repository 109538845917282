import { VAppBarNavIcon } from 'vuetify/lib/components/VAppBar';
import { VBadge } from 'vuetify/lib/components/VBadge';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VImg } from 'vuetify/lib/components/VImg';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemIcon } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VNavigationDrawer } from 'vuetify/lib/components/VNavigationDrawer';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VNavigationDrawer,{attrs:{"app":"","mini-variant":_vm.mini},on:{"update:miniVariant":function($event){_vm.mini=$event},"update:mini-variant":function($event){_vm.mini=$event}},model:{value:(_vm.openMenu),callback:function ($$v) {_vm.openMenu=$$v},expression:"openMenu"}},[_c(VList,[_c(VListItem,{style:(_vm.mini ? 'height: 51px !important;': 'margin-left: 30%;')},[(_vm.mini == false)?_c('a',{attrs:{"href":"/"}},[_c(VImg,{attrs:{"src":require('@/assets/images/logo.svg'),"max-height":"90","max-width":"60"}})],1):_c(VAppBarNavIcon,{staticStyle:{"border":"none","margin-left":"-5px"},on:{"click":function($event){$event.stopPropagation();return _vm.openCloseSideMenu.apply(null, arguments)}}},[_c(VIcon,{attrs:{"color":"var(--color__cinza_escuro)"}},[_vm._v(" mdi-playlist-plus ")])],1)],1)],1),_c(VDivider),_c(VList,{attrs:{"dense":""}},[_c('a',{staticStyle:{"text-decoration":"none !important"},attrs:{"href":"/"}},[_c(VListItem,{attrs:{"link":""}},[_c(VTooltip,{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c(VListItemIcon,_vm._g({},on),[_c(VIcon,{attrs:{"color":"var(--color__main)"}},[_vm._v("mdi mdi-home")])],1),_c(VListItemTitle,[_vm._v("Home")])]}}])},[_c('span',[_vm._v("Go to Home")])])],1)],1),_c(VListItem,{attrs:{"link":"","to":{ name: 'completedJobs' }}},[_c(VTooltip,{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c(VListItemIcon,_vm._g({},on),[_c(VIcon,{attrs:{"color":"var(--color__main)"}},[_vm._v("mdi mdi-coffee-maker-check")])],1),_c(VListItemTitle,[_vm._v("Completed Jobs")])]}}])},[_c('span',[_vm._v("Completed Jobs")])])],1),(_vm.mini == false)?_c(VListItem,[_c(VListItemTitle,{staticClass:"titleMenu"},[_vm._v("Notifications")])],1):_vm._e(),_c(VListItem,{key:-5,attrs:{"link":"","to":{ name: 'toDoUrgent', params: { categoryName: ' - Urgent' } }},on:{"click":function($event){return _vm.setIDToDoCategory(1, 0, 0, 0)}}},[_c(VTooltip,{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c(VListItemIcon,_vm._g({},on),[_c(VBadge,{attrs:{"content":_vm.qtUrgent,"value":_vm.qtUrgent,"left":"","overlap":"","color":"var(--color__red)"}},[_c(VIcon,{attrs:{"color":"var(--color__main)"}},[_vm._v("mdi mdi-fire")])],1)],1),_c(VListItemTitle,[_vm._v("Urgent")])]}}])},[_c('span',[_vm._v("Urgent")])])],1),_c(VListItem,{key:-4,attrs:{"link":"","to":{ name: 'toDoSystem', params: { categoryName: ' - System' } }},on:{"click":function($event){return _vm.setIDToDoCategory(0, 1, 0, 0)}}},[_c(VTooltip,{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c(VListItemIcon,_vm._g({},on),[_c(VBadge,{attrs:{"content":_vm.qtSystem,"value":_vm.qtSystem,"left":"","overlap":"","color":"var(--color__red)"}},[_c(VIcon,{attrs:{"color":"var(--color__main)"}},[_vm._v("mdi mdi-bell")])],1)],1),_c(VListItemTitle,[_vm._v("System")])]}}])},[_c('span',[_vm._v("System")])])],1),_c(VListItem,{key:-3,attrs:{"link":"","to":{ name: 'toDoAssignedToMe', params: { categoryName: ' - Assigned To Me' } }},on:{"click":function($event){return _vm.setIDToDoCategory(0, 0, 1, 0)}}},[_c(VTooltip,{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c(VListItemIcon,_vm._g({},on),[_c(VBadge,{attrs:{"content":_vm.qtAssignedToMe,"value":_vm.qtAssignedToMe,"left":"","overlap":"","color":"var(--color__red)"}},[_c(VIcon,{attrs:{"color":"var(--color__main)"}},[_vm._v("mdi mdi-clipboard-check")])],1)],1),_c(VListItemTitle,[_vm._v("Assigned To Me")])]}}])},[_c('span',[_vm._v("Assigned To Me")])])],1),_c(VListItem,{key:-2,attrs:{"link":"","to":{ name: 'inspection' }}},[_c(VTooltip,{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c(VListItemIcon,_vm._g({},on),[_c(VBadge,{attrs:{"content":_vm.qtdInspection,"value":_vm.qtdInspection,"left":"","overlap":"","color":"var(--color__red)"}},[_c(VIcon,{attrs:{"color":"var(--color__main)"}},[_vm._v("mdi mdi-magnify-expand")])],1)],1),_c(VListItemTitle,[_vm._v("Inspections")])]}}])},[_c('span',[_vm._v("Inspections")])])],1),_c(VListItem,{key:-1,attrs:{"link":"","to":{ name: 'workOrder' }}},[_c(VTooltip,{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c(VListItemIcon,_vm._g({},on),[_c(VBadge,{attrs:{"content":_vm.qtdWorkOrder,"value":_vm.qtdWorkOrder,"left":"","overlap":"","color":"var(--color__red)"}},[_c(VIcon,{attrs:{"color":"var(--color__main)"}},[_vm._v("mdi mdi-briefcase-outline")])],1)],1),_c(VListItemTitle,[_vm._v("Work Order")])]}}])},[_c('span',[_vm._v("Work Order")])])],1),_vm._l((_vm.userLoggedGetters.listToDoCategory),function(itemToDoCateogory,index){return _c(VListItem,{key:index,attrs:{"link":"","to":{ name: itemToDoCateogory.routeName, params: { categoryName: ' - ' + itemToDoCateogory.description } }},on:{"click":function($event){return _vm.setIDToDoCategory(0, 0, 0, itemToDoCateogory.id)}}},[_c(VTooltip,{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c(VListItemIcon,_vm._g({},on),[_c(VBadge,{attrs:{"content":_vm.listToDoByCategory.filter(function (cat) { return cat.idTodoCategory === itemToDoCateogory.id; }).length,"value":_vm.listToDoByCategory.filter(function (cat) { return cat.idTodoCategory === itemToDoCateogory.id; }).length,"left":"","overlap":"","color":"var(--color__red)"}},[_c(VIcon,{attrs:{"color":"var(--color__main)"}},[_vm._v(_vm._s(itemToDoCateogory.mdiIcon))])],1)],1),_c(VListItemTitle,[_vm._v(_vm._s(itemToDoCateogory.description))])]}}],null,true)},[_c('span',[_vm._v(_vm._s(itemToDoCateogory.description))])])],1)})],2),_c(VDivider),(_vm.itemGrupoMenu !== null)?_c(VList,{attrs:{"dense":""}},[(_vm.mini == false)?_c(VListItem,[_c(VListItemTitle,{staticClass:"titleMenu"},[_vm._v("Menu")])],1):_vm._e(),_vm._l((_vm.itemGrupoMenu.listMenu.filter(function (mnu) { return mnu.showInMenu === true; })),function(itemMenu,index){return _c(VListItem,{key:index,attrs:{"link":"","to":("" + (itemMenu.path))},on:{"click":function($event){return _vm.setIDToDoCategory(0, 0, 0, 0)}}},[_c(VTooltip,{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c(VListItemIcon,_vm._g({},on),[_c(VIcon,{attrs:{"color":"var(--color__main)"}},[_vm._v(_vm._s(itemMenu.mdiIcon))])],1),_c(VListItemTitle,[_vm._v(_vm._s(itemMenu.description))])]}}],null,true)},[_c('span',[_vm._v(_vm._s(itemMenu.description))])])],1)})],2):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }