
import Vue from 'vue'

const actions = {

    async DashboardAdmin() {

        try {
            const result = await Vue.prototype.$vanKirkApi.get("dashboard/dashboard-admin",
            {
                headers: {
                    "content-type": "application/json"
                }
            });

            if (result.data) {
                let listDashboardAdmin = result.data.result;

                return listDashboardAdmin;
            }
            else {
                return [];
            }
        }
        catch(ex) {
            return {
                success: false,
                message: `Error to list dashboards: [${ex}]`
            };
        }
    },
}

export default {
    namespaced: true,
    actions
}